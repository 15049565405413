/**
 * Created by preference on 2020/12/01
 *  zmx 
 */

import * as API from '@/api/index'
export default {
  /**  10.1.产品入库列表 */
  get_list_inventoryProductInbound: params => {
    return API.POST('api/inventoryProductInbound/list', params)
  },
  /** 10.2.产品入库详情  */
  get_info_inventoryProductInbound: params => {
    return API.POST('api/inventoryProductInbound/info', params)
  },
  /**  10.3.产品入库 */
  get_create_inventoryProductInbound: params => {
    return API.POST('api/inventoryProductInbound/create', params)
  },
}